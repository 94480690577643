import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BookAnAppointmentMainComponent from '../BookAnAppointment/BookAnAppointment'
import emailjs from 'emailjs-com';

// IMAGES
import ImgBanner from '../../Images/ImgBanner.jpeg'
import img1 from '../../Images/img1.JPG'
import img2 from '../../Images/img2.JPG'
import img4 from '../../Images/img4.JPG'
import img5 from '../../Images/img5.JPG'
import LogoBanner from '../../Images/LogoBanner.png'
import BeforeAndAfterImg from '../../Images/BeforeAndAfterImg.png'
import chimneyinspection from '../../Images/chimneyinspection.jpeg'
import chimneysweep from '../../Images/chimneysweep.jpeg'
import chimneyrepair from '../../Images/chimneyrepair.jpeg'
import fireplacebanner from '../../Images/fireplacebanner.jpeg'
import Book from '../../Images/Book.jpeg'
import './HomePage.scss';

const HomePage = () => {
    const [Menu, setMenu] = useState(false);
    const [BookAnAppointment, setBookAnAppointment] = useState(false);
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const [FormSubmited, setFormSubmited] = useState(false);
    const [CustInfo, setCustInfo] = useState({
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
        Email: '',
        Service: 'Chimney Inspection Lvl 1 | 99$',
        Date: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toISOString().substr(0, 10),
        Time: '8:00AM-11:00AM',
        Address1: '',
        Address2: '',
        City: '',
        State: 'AL',
        Zipcode: '',
        Note: ''
    });

    const location = useLocation().pathname
    const navigate = useNavigate()

    function sendEmail(e) {
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_h897je5', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                setFormSubmited(true)
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className='HomePage'>
            <div className='Header'>
                <div className="HeaderBottom">
                    <div className="HeaderInfo">
                        <a href="/">
                            <img src={LogoBanner} alt="" />
                        </a>
                    </div>
                    <div className="HeaderMenu">
                        <a href="tel:‪760-254-0817‬">‪760-254-0817‬</a>
                        {Menu ?
                            <img onClick={() => setMenu(false)} src="https://cdn-icons-png.flaticon.com/512/2976/2976286.png" alt="A1 Chimney Sweep Menu" />
                            :
                            <img onClick={() => { setMenu(true) }} src="https://cdn-icons-png.flaticon.com/512/2976/2976215.png" alt="A1 Chimney Sweep Menu" />
                        }
                    </div>
                    {
                        Menu ?
                            <div className="HeaderMainMenu">
                                <a onClick={() => setMenu(false)} href="#mainbanner" >Home</a>
                                <a onClick={() => setMenu(false)} href="#services" >Services</a>
                                <a onClick={() => setMenu(false)} href="#whyus" >About Us</a>
                                <a onClick={() => setMenu(false)} href="#footer" >Contact Us</a>
                                <div className="HeaderMainMenuMore">
                                    <a onClick={() => {
                                        setMenu(false)
                                        setBookAnAppointment(true)
                                    }}>Book An Appointment <img src="https://cdn-icons-png.flaticon.com/512/7322/7322265.png" alt="A1 Chimney Sweep Book An Appointment" /></a>
                                    <a href="tel:‪760-254-0817‬">CALL NOW! <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt="A1 Chimney Sweep Phone Number" /></a>
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
            <div className="WebSite">
                <div id="mainbanner" className="MainBanner">
                    <div className="MainBannerInfo">
                        <h1>
                            A1 Chimney Sweep
                        </h1>
                        <h2>Choose A1 Chimney Sweep for all your chimney needs and experience the peace of mind that comes with knowing your home is in good hands</h2>
                        <a href="tel:‪760-254-0817‬">‪760-254-0817‬</a>
                    </div>
                    <div className="MainBannerImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={img1} alt="CHIMNEY REPAIR SERVICE" />
                    </div>
                </div>
                <div id='services' className="Services">
                    <div className="Services1">
                        <img src={img2} alt="CHIMNEY SWEEPING & CLEANING" />
                        <h2>CHIMNEY SWEEPING & CLEANING IN San Bernardino, CA</h2>
                        <p>
                            Ensure your chimney is safe and efficient with A1 Chimney Sweep's expert chimney sweeping and cleaning services in San Bernardino, CA. Regular maintenance is crucial to prevent chimney fires and improve the performance of your fireplace. Our experienced technicians use state-of-the-art equipment and techniques to thoroughly clean your chimney, removing creosote buildup, soot, and debris.

                            Our chimney sweeping and cleaning services include:

                            Comprehensive Cleaning: We provide a detailed cleaning of your chimney's interior, including the flue, smoke chamber, and firebox, ensuring all contaminants are effectively removed.
                            Creosote Removal: Creosote is a highly flammable byproduct of burning wood. We focus on removing this buildup to reduce the risk of chimney fires and improve air quality.
                            Inspection and Safety Check: During the cleaning process, we also perform a basic inspection to identify any potential issues or hazards, ensuring your chimney is in safe working condition.
                            Smoke and Odor Elimination: Our cleaning services help eliminate smoke and odors caused by soot and creosote, leaving your home smelling fresh and clean.
                            Improved Efficiency: A clean chimney allows for better airflow, improving the efficiency of your fireplace and ensuring a more enjoyable and safe experience.
                            At A1 Chimney Sweep, we are committed to providing exceptional chimney sweeping and cleaning services that keep your home safe and your chimney in optimal condition. Contact us today to schedule your chimney cleaning and experience the benefits of a well-maintained chimney in San Bernardino, CA.
                        </p>
                    </div>
                    <div className="Services2">
                        <img src={img4} alt="CHIMNEY INSPECTION SERVICE" />
                        <h2>CHIMNEY INSPECTION SERVICE IN San Bernardino, CA</h2>
                        <p>
                            Keep your home safe and your chimney functioning efficiently with A1 Chimney Sweep's comprehensive chimney inspection services in San Bernardino, CA. Regular inspections are essential for identifying potential issues and ensuring the safety of your chimney. Our certified professionals conduct thorough inspections to detect any problems early, preventing costly repairs and hazards.

                            Our chimney inspection services include:

                            Detailed Visual Inspections: We thoroughly examine the interior and exterior of your chimney, checking for signs of damage, blockages, and structural issues.
                            Creosote Buildup Assessment: Our experts assess the level of creosote buildup, a common cause of chimney fires, and recommend cleaning if necessary.
                            Structural Integrity Evaluation: We inspect the masonry, chimney cap, and crown to ensure they are in good condition and free from cracks or deterioration.
                            Flue and Smoke Chamber Inspection: Using advanced tools, we inspect the flue and smoke chamber for any damage or obstructions that could affect proper ventilation.
                            Leak Detection: We check for any signs of water damage or leaks, which can lead to significant structural problems if left unaddressed.
                            Regular chimney inspections are vital for maintaining a safe and efficient fireplace. Trust A1 Chimney Sweep to provide reliable and thorough inspections, giving you peace of mind that your chimney is in excellent condition. Schedule your chimney inspection today and ensure the safety of your home in San Bernardino, CA.
                        </p>
                    </div>
                    <div className="Services3">
                        <img src={img5} alt="CHIMNEY REPAIR SERVICE" />
                        <h2>CHIMNEY REPAIR SERVICE</h2>
                        <p>
                            Ensure your chimney is safe and functional with A1 Chimney Sweep's expert chimney repair services in San Bernardino, CA. Over time, chimneys can develop various issues due to weather, wear and tear, and regular use. Our skilled technicians are equipped to handle a wide range of repairs, from minor fixes to major restorations, ensuring your chimney remains in optimal condition.

                            Our chimney repair services include:

                            Masonry Repairs: Cracks, gaps, and deteriorating mortar can compromise the structural integrity of your chimney. We offer professional masonry repairs to restore the strength and appearance of your chimney.
                            Chimney Relining: A damaged flue liner can lead to inefficient ventilation and increased fire risk. We provide chimney relining services using high-quality materials to ensure safe and effective operation.
                            Crown and Cap Repairs: The chimney crown and cap protect against water damage and debris. We repair or replace damaged crowns and caps to keep your chimney well-protected.
                            Leak Repair: Water leaks can cause extensive damage to your chimney and home. Our leak repair services address the source of the problem, preventing further issues and costly repairs.
                            Firebox Repair: The firebox can suffer from cracks and damage due to intense heat. We offer firebox repair services to maintain a safe and efficient fireplace.
                            Smoke Chamber Repair: A properly functioning smoke chamber is essential for good airflow. We repair smoke chambers to ensure optimal performance and safety.
                            At A1 Chimney Sweep, we are committed to providing high-quality chimney repair services that enhance the safety and longevity of your chimney. Contact us today to schedule your repair service and keep your chimney in top condition in San Bernardino, CA.
                        </p>
                    </div>
                </div>
                <div className="ServicesCallNow">
                    <div className="ServicesCallNowInfo">

                        <h1>
                            Excited to experience our top-notch service? Simply click the button below and give us a call today!</h1>
                        <a href={`tel:‪760-254-0817‬`}>CALL NOW !</a>
                    </div>
                    <div className="ServicesCallNowImg">
                        <div className="ServicesCallNowImgDark"></div>
                        <img src={img1} alt="CHIMNEY SWEEPING & CLEANING" />
                    </div>
                </div>
                <div className="ServicesMore">

                    <h1>MORE OF OUR SERVICES:</h1>
                    <div className="ServicesMoreBoxMain">
                        <div className="ServicesMoreBoxMainLeft">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cap Repair San Bernardino, CA" />
                                <a href="/Chimney-Cap-Repair">Chimney Cap Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cleaning San Bernardino, CA" />
                                <a href="/Chimney-Cleaning">Chimney Cleaning San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Construction San Bernardino, CA" />
                                <a href="/Chimney-Construction">Chimney Construction San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Crown Repair San Bernardino, CA" />
                                <a href="/Chimney-Crown-Repair">Chimney Crown Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Installation San Bernardino, CA" />
                                <a href="/Chimney-Flue-Installation">Chimney Flue Installation San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flue Repair San Bernardino, CA" />
                                <a href="/Chimney-Flue-Repair">Chimney Flue Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Inspection San Bernardino, CA" />
                                <a href="/Chimney-Inspection">Chimney Inspection San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Installation San Bernardino, CA" />
                                <a href="/Chimney-Installation">Chimney Installation San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Insulation San Bernardino, CA" />
                                <a href="/Chimney-Insulation">Chimney Insulation San Bernardino, CA</a>
                            </div>
                        </div>
                        <div className="ServicesMoreBoxMainMiddle">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Cracks Repair San Bernardino, CA" />
                                <a href="/Chimney-Cracks-Repair">Chimney Cracks Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Paint San Bernardino, CA" />
                                <a href="/Chimney-Firebox-Paint">Chimney Firebox Paint San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Cleaining San Bernardino, CA" />
                                <a href="/Chimney-Firebox-Cleaining">Chimney Firebox Cleaining San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Firebox Inspection San Bernardino, CA" />
                                <a href="/Chimney-Firebox-Inspection">Chimney Firebox Inspection San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Best Company San Bernardino, CA" />
                                <a href="/Chimney-Best-Company">Chimney Best Company San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firebox Inspection San Bernardino, CA" />
                                <a href="/Firebox-Inspection">Firebox Inspection San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firebox Sweeping San Bernardino, CA" />
                                <a href="/Firebox-Sweeping">Firebox Sweeping San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Fireplace Inspection San Bernardino, CA" />
                                <a href="/Fireplace-Inspection">Fireplace Inspection San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Firepalce Repair San Bernardino, CA" />
                                <a href="/Firepalce-Repair">Firepalce Repair San Bernardino, CA</a>
                            </div>
                        </div>
                        <div className="ServicesMoreBoxMainRight">
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Repair San Bernardino, CA" />
                                <a href="/Chimney-Liner-Repair">Chimney Liner Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Maintenance San Bernardino, CA" />
                                <a href="/Chimney-Maintenance">Chimney Maintenance San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Pointing San Bernardino, CA" />
                                <a href="/Chimney-Pointing">Chimney Pointing San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Rain Cap Installation San Bernardino, CA" />
                                <a href="/Chimney-Rain Cap-Installation">Chimney Rain Cap Installation San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Repair San Bernardino, CA" />
                                <a href="/Chimney-Repair">Chimney Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Restoration San Bernardino, CA" />
                                <a href="/Chimney-Restoration">Chimney Restoration San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Flashing Repair San Bernardino, CA" />
                                <a href="/Chimney-Flashing-Repair">Chimney Flashing Repair San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Liner Installation San Bernardino, CA" />
                                <a href="/Chimney-Liner-Installation">Chimney Liner Installation San Bernardino, CA</a>
                            </div>
                            <div className="ServicesMoreBox">
                                <img src="https://cdn-icons-png.flaticon.com/512/2760/2760290.png" alt="Chimney Damper Repair San Bernardino, CA" />
                                <a href="/Chimney-Damper-Repair">Chimney Damper Repair San Bernardino, CA</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="BeforeAndAfter">
                    <h1>Why Choose A1 Chimney Sweep?</h1>
                    <img src={img2} alt="Is it time to clean your chimney?" />
                    <h2>There are several factors that increase the risk of chimney fires. These include:</h2>
                    <h3>
                        At A1 Chimney Sweep, we pride ourselves on delivering exceptional chimney services that prioritize the safety and satisfaction of our customers in San Bernardino, CA. Here are several reasons why A1 Chimney Sweep is the best choice for all your chimney needs:
                        <br />
                        Highly Skilled Technicians: Our team consists of certified and experienced professionals who are knowledgeable about the latest techniques and industry standards. You can trust us to provide thorough and expert chimney services.
                        <br />
                        <br />

                        Comprehensive Services: We offer a full range of chimney services, including sweeping, cleaning, inspections, repairs, and more. Whether you need routine maintenance or emergency repairs, we have you covered.
                        <br />

                        Safety First: We prioritize your safety by conducting detailed inspections and using top-quality materials for all repairs. Our meticulous approach ensures your chimney is safe and functional.
                        <br />
                        <br />

                        Customer Satisfaction: Our commitment to exceptional customer service means we go above and beyond to meet your needs. From your first call to the completion of the job, we aim to exceed your expectations.

                        Affordable Pricing: We provide high-quality services at competitive prices. Our transparent pricing ensures there are no hidden fees, so you know exactly what to expect.
                        <br />
                        <br />

                        Local Expertise: As a locally owned and operated business, we understand the specific needs of homeowners in San Bernardino, CA. Our personalized service is tailored to the unique conditions and requirements of the area.

                        Trust and Reliability: We have built a reputation for being reliable and trustworthy. Our clients rely on us for our honesty, integrity, and dedication to quality work.
                        <br />

                        Convenient Scheduling: We make it easy to book your chimney services. Whether you prefer to schedule over the phone or online, our process is simple and hassle-free.

                        Choose A1 Chimney Sweep for all your chimney needs and enjoy the peace of mind that comes with knowing your home is in expert hands. Contact us today to schedule your service and discover why we are the preferred chimney sweep company in San Bernardino, CA.
                    </h3>

                </div>
                <div className="BookAnAppointmentMain">
                    <div className="BookAnAppointmentMainLeft">
                        <h1>Schedule Your Chimney Service with Ease</h1>
                        <p>
                            At San Bernardino A1 Chimney Sweep, we are committed to making the process of booking your chimney services as convenient as possible. Whether you need chimney sweeping, cleaning, inspection, or repair, scheduling an appointment with us is simple and straightforward. You can book your service over the phone or online, ensuring a hassle-free experience.
                        </p>
                    </div>
                    <div className="BookAnAppointmentMainRight">
                        <div className="BookAnAppointmentMainRightInfo">
                            <h1>Book an Appointment Online Now...</h1>
                            <button onClick={() => setBookAnAppointment(true)}>BOOK NOW</button>
                        </div>

                    </div>
                    <div className="BookAnAppointmentMainRightImg">
                        <div className="MainBannerImgDark"></div>
                        <img src={img5} alt="Is it time to clean your chimney?" />
                    </div>
                    {
                        BookAnAppointment ?
                            <BookAnAppointmentMainComponent setBookAnAppointment={setBookAnAppointment} CustInfo={CustInfo} />
                            :
                            null}
                </div>
                <div id='whyus' className="WhyUs">
                    {/* <img src="https://i.insider.com/61dca5f64ede320018b6e5a6?width=900&format=jpeg&auto=webp" alt="" /> */}
                    <div className="WhyUsTitle">
                        <h1>Why Choose A1 Chimney Sweep?</h1>
                        <p>Choosing A1 Chimney Sweep means opting for excellence in chimney care in San Bernardino, CA. Here’s why we stand out:</p>
                    </div>
                    <div className="WhyUsList">
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2780/2780505.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Experienced Professionals</h2>
                                <h3>Expert Technicians: Our team comprises certified professionals with extensive experience and training in all aspects of chimney maintenance and repair. We bring expertise and precision to every job..</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/2212/2212838.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Comprehensive Services</h2>
                                <h3>All-Inclusive Services: From chimney sweeping and cleaning to thorough inspections and complex repairs, we offer a full spectrum of services to meet all your chimney needs under one roof.</h3>
                            </div>
                        </div>
                        <div className="WhyUsLkistBox">
                            <div className="WhyUsLkistBoxLeft">
                                <img src="https://cdn-icons-png.flaticon.com/512/807/807303.png" alt="Chimney Sweep Near Me" />
                            </div>
                            <div className="WhyUsLkistBoxRight">
                                <h2>Safety First</h2>
                                <h3>Commitment to Safety: Your safety is our foremost priority. We perform meticulous inspections and utilize high-quality materials to ensure your chimney is safe, reliable, and functioning efficiently.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id='footer' className="Footer">
                <div className="FooterFormMain">
                    <div className="FooterFormTitle">
                        <h1>CONTACT US FOR MORE DETAILS</h1>
                    </div>
                    <div className="FooterForm">

                        <form className="contact-form" onSubmit={sendEmail}>
                            <h3>Name</h3>
                            <input type='text' name="Name" />
                            <h3>Phone</h3>
                            <input type='tel' name="Phone" />
                            <h3>Location (Address)</h3>
                            <input type='text' name="Location" />
                            <h3>Message</h3>
                            <input type='text' name="Message" />

                            <input type="hidden" name="Company_From" defaultValue={`A1 Chimney Sweep`} />

                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                    </button>
                                    :

                                    <input type="submit" value="SEND" />
                            }
                        </form>
                    </div>
                </div>
                <div className="FooterContactUs">
                    <div className="FooterContactUsTitle">
                        <h2>CONTACT DETAILS</h2>
                        <h3>If you have any questions at all, please feel free to contact us. We are available via phone or email. You can also use a quick contact form below or visit us personally.</h3>
                    </div>
                    <div className="FooterContactUsInfo">
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/597/597177.png" alt={`A1 Chimney Sweep Phone Number ‪760-254-0817‬`} />
                            <h2>Phone Number: <a href="tel:760-254-0817">760-254-0817</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/646/646135.png" alt="A1 Chimney Sweep Email | info@San Bernardino-aaachimneysweep.coml" />
                            <h2>Email: <a href="mailto:info@San Bernardino-aaachimneysweep.com">info@San Bernardino-aaachimneysweep.com</a></h2>
                        </div>
                        <div className="FooterContactUsInfoBox">
                            <img src="https://cdn-icons-png.flaticon.com/512/1006/1006771.png" alt="A1 Chimney Sweep Website | www.sanbernardino-a1chimneysweep.com" />
                            <h2>Website: <a href="https://www.sanbernardino-a1chimneysweep.com/">www.sanbernardino-a1chimneysweep.com</a></h2>
                        </div>

                    </div>
                    <div className="FooterContactUsAbout">
                        <h2>
                            Our Customers Rates: 5.0 Stars
                        </h2>
                        <h3>
                            © Copyright 2022 A1 Chimney Sweep. All Right Reserved. <span onClick={() => navigate('/sitemap')}>Sitemap</span>
                        </h3>
                    </div>
                </div>

            </div>
            {
                !BookAnAppointment ?
                    <div className="CallNow">
                        {/* <h1>CALL NOW !</h1> */}
                        <a href="tel:‪760-254-0817‬">CALL NOW !</a>
                    </div>
                    :
                    null
            }
            {
                !BookAnAppointment ?
                    <div className="BookAnAppointmentButton" onClick={() => setBookAnAppointment(true)}>
                        <h1>BOOK ONLINE</h1>
                    </div>
                    :
                    null
            }
        </div>
    );
}

export default HomePage;
